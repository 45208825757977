<template>
    <div id="container">
        <div id="contents">
            <div class="contents_header">
                <div class="MenuBox">
                    <div class="m1dep">공통코드 관리</div>
                    <div class="m2dep">
                        <ul>
                            <li class="over" @click="$router.push({name:'ADMCODE01M01'})">공통 분류코드 등록/수정</li>
                            <li class="on">공통 코드 등록/수정</li>
                            <li class="over" @click="$router.push({name:'ADMCODE03M01'})">공통 상세코드 등록/수정</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="contents_body">
                <div class="Search">
                    <div class="inner">
                        <div class="select_wrap">
                            <select v-model="input.searchCondition"> 
                                <option value="" label="전체"/>
                                <option value="1" label="업무중분류코드"/>
                                <option value="2" label="업무중분류코드명" />
                            </select>
                        </div>
                        <input type="text" name="" id="" class="s215" v-model="input.searchKeyword" @keyup.enter="search"/>
                        <div class="select_wrap">
                            <select v-model="input.searchClCode"> 
                                <option value="" label="업무분류코드명"/>
                                <option v-for="option in clCodeList" :key="option.clCode" :value="option.clCode">
                                    {{option.clCodeNm}}
                                </option>
                            </select>
                        </div>
                        <div class="select_wrap">
                            <select v-model="input.useAt"> 
                                <option value="" label="사용여부"/>
                                <option value="Y" label="예"/>
                                <option value="N" label="아니요"/>									
                            </select>
                        </div>
                        <span @click="search" class="btn gray">검색</span>
                    </div>
                </div>
                <div class="Board">
                    <table class="Board_type1">
                        <colgroup>
                            <col width="100">
                            <col width="">
                            <col width="200">
                            <col width="200">
                            <col width="100">
                        </colgroup>
                        <thead>
                            <tr>
                                <th>NO</th>
                                <th>업무분류코드명</th>
                                <th>업무중분류코드</th>
                                <th>업무중분류코드명</th>
                                <th>사용여부</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(result, index) in codeList" :key="result.codeId">
                                <td scope="row">{{ (pageInfo.pageIndex - 1) * pageInfo.pageSize + (index + 1) }}</td>
                                <td>{{result.clCodeNm}}&nbsp;</td>
                                <td class="over" @click="select(result.codeId)">{{result.codeId}}&nbsp;</td>
                                <td class="over" @click="select(result.codeId)">{{result.codeIdNm}}&nbsp;</td>
                                <td>{{result.useAt}}&nbsp;</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                
                <!--페이징 컴포넌트 -->
                <pagingComp :pageInfo="pageInfo" @page-click="goPage"/>
                <!--// 페이징 컴포넌트 -->

                <!--버튼영역-->
                <div class="BtnArea"> 
                    <div class="Btninner right">
                        <span class="btn write" @click="regBtn">등록</span>
                    </div>
                </div>
                <!--//버튼영역-->   

            </div><!--//contents_body-->
        </div><!--//contents-->
    </div>
    <!--//container-->
</template>

<script>
    import pagingComp from "@/components/PagingComp.vue";
    export default {
        components: {
            pagingComp
        },
        data() {
            return {
                input: {
                    searchCondition : '',
                    searchKeyword : '',
                    searchClCode : '',
                    useAt : ''
                }, // 검색조건

                clCodeList: [],
                codeList: [],

                pageInfo: {},
                pageIndex: 1
            };
        },
        beforeCreate() { 
            // console.log('beforeCreate');
        },
        created() { 
            // console.log('created');
        },
        beforeMount() { 
            // console.log('beforeMount');
        },
        mounted() { 
            // console.log('mounted');
            this.getClCodeList();
            this.getCodeList();
        },
        beforeUpdate() { 
            // console.log('beforeUpdate');
        },
        updated() { 
            // console.log('BBS001M01 update');
        },
        beforeDestroy() {
            // console.log("beforeDestroy");
        },
        destroyed() {
            // console.log("destroyed");
        },
        methods: {

            search() {
                this.pageIndex = 1;
                this.getCodeList();
            },

            // 전체 공통코드 조회해서 clCodeList만 사용
            getClCodeList() {
            
                this.$.httpPost("/api/code/getCommonCode")
                    .then((res) => {
                        // console.log(res.data);
                        
                        this.clCodeList = res.data.clCode;
                    })
                    .catch((err) => {
                        // console.log("=============================");
                        // console.log(err);
                        // console.log(err.response);
                        // console.log("=============================");
                        alert(err
                            .response
                            .data
                            .error_description);
                    });
            },

            // 업무중분류코드 목록 조회
            getCodeList() {
            
                this.input.pageIndex = this.pageIndex;

                this.$.httpPost("/api/code/adm/getCodeList", this.input)
                    .then((res) => {
                        // console.log(res.data);
                        
                        this.codeList = res.data.list;
                        this.pageInfo = res.data.pageInfo;
                    })
                    .catch((err) => {
                        // console.log("=============================");
                        // console.log(err);
                        // console.log(err.response);
                        // console.log("=============================");
                        alert(err
                            .response
                            .data
                            .error_description);
                    });
            },
            
            select(codeId) {
                // 상세 페이지로 이동
                var page = {
                    name : 'ADMCODE02M02',
                    params : {
                        codeId : codeId
                    }
                }
                this.$router.push(page);
            },
            
            goPage(page) { // console.log(page);
                this.pageIndex = page;
                this.getCodeList();
            },
            
            regBtn() {
                this.$router.push({name:'ADMCODE02M03'});
            },
            
        }
    };
</script>